import styled from 'styled-components'
import Button from '../Button'

export const PageBody = styled('div')`
  padding-top: 71px;
  @media(max-width: 920px) {
    padding-top: 109px;
    padding-bottom: 80px;
  }
`
export const BookButton = styled(Button)`
  &.btn {
    @media (max-width: 920px) {
      display: block;
      width: 100%;
      padding: 13px 24px;
    }
  }
`
export const NavBarButton = styled(Button)`
  &.btn-lg {
    height: 50px;
    padding: 13px 24px;
    margin-left: auto;
    margin-right: 32px;
    font-size: 16px;
  }
`


