import styled from 'styled-components'
import Button from './Button'
import React from 'react'

const BarStyled = styled('div')`
  @media(min-width: 921px) {
    display: none;
  }
  position: fixed;
  bottom: 0;
  padding: 12px;
  width: 100%;
  background: #fff;
  border-top: 1px solid #e8e8e8;
  z-index: 5000;
  .btn {
    padding: 14px 16px;
  }
`

const BottomBar = function ({ show = false, content = null, action = null }) {
  const doShow = React.useMemo(() => show || content !== null, [show, content])

  return doShow && <BarStyled>
    { content || (
      action && <Button block onClick={() => action()}>Book an appointment</Button>
    )}
  </BarStyled>
}

export default BottomBar
