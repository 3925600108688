import styled from 'styled-components'

const tick = require('../../assets/images/tick.svg')

const TicksUl = styled.ul`
  list-style: none;
  padding-left: 28px;
  @media (max-width: 920px) {
    margin-bottom: 0;
  }
  li {
    position: relative;
    margin-bottom: 10px;
    &:before {
      display: block;
      position: absolute;
      left: -22px;
      top: 5px;
      content: ' ';
      background-image: url('${tick}');
      background-size: 12px 12px;
      height: 12px;
      width: 12px;
    }
  }
`

export default TicksUl
