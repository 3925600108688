import styled from 'styled-components'
import {
  H2,
  H3,
  H4,
  H5,
} from './Typography'

import DlDefault from '@bootstrap-styled/v4/lib/Dl'
import DdDefault from '@bootstrap-styled/v4/lib/Dd'
import DtDefault from '@bootstrap-styled/v4/lib/Dt'

export const Article = styled.article`
  p {
    text-align: left;
    text-align-last: left;
  }
  strong {
    font-weight: 500;
  }
  img {
    max-width: 100%;
  }
`

export const Section = styled.section`
  p {
    text-align: left;
    text-align-last: left;
  }
  strong {
    font-weight: 500;
  }
  img {
    max-width: 100%;
  }
`

export const ArticleHeading = styled(H2)`
  margin-top: 64px;
  margin-bottom: 18px;
`

export const ArticleHeadingH3 = styled(H3)`
  margin-top: 64px;
  margin-bottom: 18px;
`

export const ArticleHeadingH4 = styled(H4)`
  margin-top: 48px;
  margin-bottom: 18px;
`

export const ArticleHeadingH5 = styled(H5)`
  margin-top: 36px;
  margin-bottom: 18px;
`

export const ContentHeading = styled(H3)`
  
`

export const Dl = styled(DlDefault)`
  color: ${(props) => props.theme['$headings-color']};
`
export const Dt = styled(DtDefault)`
  font-weight: ${(props) => props.active ? '500' : 'normal'};
`
export const Dd = styled(DdDefault)`
  font-weight: ${(props) => props.active ? '500' : 'normal'};
`

