import styled from 'styled-components'
import Modal from '@bootstrap-styled/v4/lib/Modal'
import ModalBody from '@bootstrap-styled/v4/lib/Modal/ModalBody'
import ModalHeader from '@bootstrap-styled/v4/lib/Modal/ModalHeader'

import Button from '../Button'
import { H4 } from '../Typography'
import Col from '@bootstrap-styled/v4/lib/Col'
import { Typeahead as TA } from 'react-typeahead'

export const Typeahead = styled(TA)`
.hover {
  border-style: dotted;
  border-width: 1px;
  background-color: LightGray;
  margin: -1px;
}

.typeahead-option {
  padding: 0px 4px 0px 4px;
}
.typeahead-token {
  border-style: solid;
  border-color: gray;
  border-width: 1px 2px 2px 1px;
  padding: 1px 5px 1px 5px;
  margin-right: 4px;
  display: inline;
  border-radius: 5px;
}
.typeahead, .typeahead input, #example, .typeahead-tokenizer {
  display: inline;
}
.typeahead-token-close {
  text-decoration: none;
  margin-left: 8px;
}
`

export const ModalWindow = styled(Modal)`
  .modal-lg {
    max-width: 918px;
  }
  @media (max-width: 920px) {
    .modal-content {
      min-height: 100vh;
      border: 0 none;
    }
  }
`
export const Body = styled(ModalBody)`
  &.modal-body {
    padding: 0;
  }
`
export const Header = styled(ModalHeader)`
  &.modal-header {
    display: block;
    padding: 10px 24px;
    color: ${(props) => props.theme['$headings-color']}
    border-bottom: 1px solid #e8e8e8;
  }
  .modal-title {
    display: flex;
    flex-direction: row;
  }
`
export const IconBlock = styled('div')`
  padding: 7px;
  font-size: 24px;
  margin-right: 24px;
  ${props => props.clickable && `
    cursor: pointer;
    &:hover {
      color: ${props.theme.$colors['blue']};
    }
  `}
`
export const Close = styled(IconBlock)`
  margin-left: auto;
  margin-right: 0;
`
export const TitleBlock = styled('div')`
  padding: 3px 0;
  .title {
    font-size: 14px;
    font-weight: 500;
  }
`
export const Content = styled('div')`
  padding: 0;
`
export const Btn = styled(Button)`
  &.btn-lg {
    font-size: 16px;
    margin-bottom: 16px;
  }
`
export const TitleLight = styled(H4)`
  font-weight: 100;
  text-align: center;
  margin-bottom: 42px;
`
export const FormColumn = styled(Col)`
  padding: 24px !important;
  @media (min-width: 921px) {
    ${props => !props.fullHeight ? `
    height: 500px;
    overflow-y: auto;
    ` : ''}
    &:not(:first-child) {
      padding-right: 44px !important;
      padding-left: 36px !important;
      border-left: 1px solid #e8e8e8;
    }
    &:only-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  form {
    width: 100%;
  }
`
