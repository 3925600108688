import React from 'react'
import { Typeahead } from './BookingModalStyled'
import { useGoogleMap } from '../GoogleMapProvider'

function GoogleSuburbsSelection ({
  inputClass = 'form-control form-control-lg',
  customClasses = {},
  onOptionSelected,
  onLoading,
  ...props
}) {
  const [suburbs, setSuburbs] = React.useState([])
  const [suburbInput, setSuburbInput] = React.useState('')

  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const onFocus = () => setShowSuggestions(true)
  const onBlur = () => setShowSuggestions(false)

  const onKeyUp = (e) => {
    if (suburbInput !== e.target.value) setSuburbInput(e.target.value)
  }

  const {
    getDetails,
    getPlacePredictions,
  } = useGoogleMap()

  React.useEffect(() => {
    if (!suburbInput) return;
    onLoading && onLoading(true)
    getPlacePredictions({
      input: suburbInput,
      componentRestrictions: {
        country: 'au',
      },
      types: ['(regions)'],
    }).then((r) => {
      onLoading && onLoading(false)
      if (!r) r = []
      setSuburbs(r.map(s => ({
        ...s,
        name: `${s.terms[0].value} (${s.terms[1].value})`
      })))
    })
  }, [suburbInput])

  const onSelected = (predicted) => {
    onLoading && onLoading(true)
    getDetails({
      placeId: predicted.place_id,
    }).then((place) => {
      onOptionSelected({
        place,
        predicted,
      })
      onLoading && onLoading(false)
    })
  }

  return <Typeahead
    options={suburbs}
    displayOption={'name'}
    filterOption={'name'}
    onKeyUp={onKeyUp}
    onBlur={onBlur}
    onFocus={onFocus}
    onOptionSelected={onSelected}
    customClasses={{
      input: inputClass,
      results: `dropdown-menu ${showSuggestions ? 'show' : 'hide'}`,
      listItem: 'dropdown-item',
      listAnchor: 'dropdown-item-text',
      ...customClasses,
    }}
    {...props}
  />
}

export default GoogleSuburbsSelection
